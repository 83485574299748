import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Box, Typography, Tabs, Tab, CircularProgress, Button } from '@mui/material';
import ResultOverviewComponent from '../components/ResultOverviewComponent';
import { fetchUserResults } from '../remote-data';
import { UserResult } from '../types';

const UserResultsPage: React.FC = () => {
  const { userId } = useParams<{ userId?: string }>();
  const [searchParams] = useSearchParams();
  const [userResults, setUserResults] = useState<UserResult[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const coupon = searchParams.get('coupon'); // クーポンを取得

  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      setLoading(true);
      fetchUserResults(userId)
        .then(({ userResults }) => {
          setUserResults(userResults);
        })
        .catch((error) => {
          console.error('Error fetching user results:', error);
          setError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [userId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleViewResult = () => {
    const selectedSubmissionId = userResults[selectedTab].submissionId;
    const queryString = coupon ? `?coupon=${coupon}` : '';
    navigate(`/result/${selectedSubmissionId}${queryString}`,  { state: { userId } });
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ my: 4, textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>
            診断結果を読み込んでいます...
          </Typography>
        </Box>
      </Container>
    );
  }

  if (error || !userResults.length) {
    return (
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Typography variant="h6" color="error" align="center">
            診断結果が見つかりません。
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom align="center" color="primary">
          ユーザー結果一覧
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {userResults.map((result, index) => (
            <Tab
              label={`結果 ${index + 1} (${result.createdAt ? new Date(result.createdAt).toLocaleDateString() : '日付不明'})`}
              key={result.submissionId}
            />
          ))}
        </Tabs>
        <Box sx={{ mt: 2 }}>
          <ResultOverviewComponent resultOverview={userResults[selectedTab].resultOverview} />
        </Box>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleViewResult}>
            この結果を見る
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UserResultsPage;
