// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { log } from "console";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBC6QEwBpfi7ByH0Ht3kWETsZpyWbOadNE",
  authDomain: "futoko-shindan.firebaseapp.com",
  projectId: "futoko-shindan",
  storageBucket: "futoko-shindan.appspot.com",
  messagingSenderId: "464484880864",
  appId: "1:464484880864:web:c5d61aa472b9eb845e9b85",
  measurementId: "G-3D0MZK5864"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// ユーザーの進行状況を追跡するための関数
export const trackUserProgress = (sectionIndex: number) => {
    logEvent(analytics, `section_${sectionIndex + 1}_start`);
};

export const trackSubmission = () => {
  console.log('trackSubmission');
  logEvent(analytics, 'submission');
}

export const trackToggleExpand = () => {
  console.log('trackToggleExpand');
  logEvent(analytics, 'expand');
}

export const trackBunnerClick = () => {
  console.log('trackBunnerClick');
  logEvent(analytics, 'banner_click');
}

export const setUserDemographics = (childGrade: string, relation: string, prefecture: string, childSituation: string) => {
  setUserProperties(analytics, {
    childGrade: childGrade,
    relation: relation,
    prefecture: prefecture,
    childSituation: childSituation
  });
}

export const setUserResult = (overallGrade: string) => {
  setUserProperties(analytics, {
    overallGrade: overallGrade
  })
}


export { analytics };