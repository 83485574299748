import React from 'react';
import { PreQuestionAnswer, PreQuestionData } from '../types';
import { Select, MenuItem, FormControl, InputLabel, Button, Box, Typography, Paper, Grid } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import Banner from './Banner';

interface PreQuestionsProps {
  preQuestions: PreQuestionData[];
  answers: PreQuestionAnswer;
  onAnswer: (name: string, value: string) => void;
  onSubmit: () => void;
}

const PreQuestions: React.FC<PreQuestionsProps> = ({ preQuestions, answers, onAnswer, onSubmit }) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const name = event.target.name;
    const value = event.target.value;
    onAnswer(name, value);
  };

  return (
    <Paper elevation={0} sx={{ p: 4, mt: 0 }}>
      <Typography variant="h5" gutterBottom align="center" color="primary">
        事前質問
      </Typography>
      <Box component="form" sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {preQuestions.map((question) => (
            <Grid item xs={12} sm={6} key={question.name}>
              <FormControl fullWidth>
                <InputLabel id={`${question.name}-label`}>{question.label}</InputLabel>
                <Select
                  labelId={`${question.name}-label`}
                  name={question.name}
                  value={answers[question.name as keyof PreQuestionAnswer] || ''}
                  onChange={handleChange}
                  required
                  label={question.label}
                >
                  {question.selections.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button type="button" variant="contained" color="primary" size="large" onClick={onSubmit}>
            診断を開始する
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default PreQuestions;