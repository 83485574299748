// components/EmailSection.tsx
import React from 'react';
import { Box, TextField, Typography, Paper } from '@mui/material';

interface EmailSectionProps {
  email: string;
  emailError: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EmailSection: React.FC<EmailSectionProps> = ({
  email,
  emailError,
  onChange,
}) => {
  return (
    <Paper elevation={0} sx={{ p: 4, mt: 1 }}>
      <Typography variant="h5" gutterBottom align="center" color="primary">
        メールアドレスの入力
      </Typography>
      <Box component="form" sx={{ mt: 3 }}>
        <TextField
          fullWidth
          type="email"
          label="メールアドレス"
          value={email}
          onChange={onChange}
          error={!!emailError}
          helperText={emailError}
          placeholder="example@email.com"
          required
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default EmailSection;