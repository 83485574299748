import Papa from 'papaparse';
import { Question, Section, Answer, PreQuestionData, PreQuestionAnswer, Result, UserResult, SubmissionData } from './types';

const API_BASE_URL = 'https://asia-northeast1-futoko-shindan.cloudfunctions.net/api';
// const API_BASE_URL = 'http://localhost:5001/futoko-shindan/asia-northeast1/api';

async function fetchFromAPI<T>(
  endpoint: string,
  selections?: {
    method?: string;
    headers?: Record<string, string>;
    body?: any;
  }
): Promise<T> {
  const { method = 'GET', headers = {}, body } = selections || {};
  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  });
  if (!response.ok) {
    throw new Error(`API request failed: ${response.statusText}`);
  }
  return response.json();
}


export async function fetchPreQuestions(): Promise<PreQuestionData[]> {
    const preQuestions: PreQuestionData[] = [
        {
          name: 'grade',
          label: 'お子さんの学年',
          selections: [
            '小学1年生', '小学2年生', '小学3年生', '小学4年生', '小学5年生', '小学6年生',
            '中学1年生', '中学2年生', '中学3年生',
            '高校1年生', '高校2年生', '高校3年生'
          ]
        },
        {
          name: 'relation',
          label: '続柄',
          selections: ['父', '母', 'その他']
        },
        {
          name: 'prefecture',
          label: '都道府県',
          selections: [
            '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
            '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
            '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県',
            '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県',
            '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県',
            '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県',
            '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
          ]
        },
        {
          name: 'situation',
          label: 'お子さんの状況',
          selections: ['通学中', '不登校', 'その他']
        }
      ];
    
    // この関数は実際にはAPIからデータを取得する代わりに、
    // ローカルに定義された preQuestions を返します。
    // 将来的にAPIから取得する場合は、ここでフェッチ処理を実装します。
    return new Promise((resolve) => {
      setTimeout(() => resolve(preQuestions), 100); // 非同期処理をシミュレート
    });
  }

  export async function fetchAllData(): Promise<{
    questions: Question[],
    sections: Section[],
    preQuestions: PreQuestionData[]
  }> {
    try {
      const result = await fetchFromAPI<{
        questions: Question[],
        sections: Section[],
        preQuestions: PreQuestionData[],
      }>('/questions');

      // console.log('fetchAllData:', result);
      return {
        questions: result.questions,
        sections: result.sections,
        preQuestions: result.preQuestions
      };
    } catch (error) {
      console.error('Error in fetchAllData:', error);
      throw error;
    }
  }

  export async function submitFormData(submissionData: SubmissionData): Promise<string> {
    try {
      const data = await fetchFromAPI<{ id: string }>('/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { 
          preQuestionAnswers: submissionData.preQuestionAnswers, 
          answers: submissionData.answers,
          result: submissionData.result, 
          email: submissionData.email, 
          coupon: submissionData.coupon, 
          requestId: submissionData.requestId 
        },
      });
      return data.id;
    } catch (error) {
      console.error('Error in submitFormData:', error);
      throw error;
    }
  }
  
  export async function fetchResult(id: string): Promise<{
    result: Result,
    demographics: PreQuestionAnswer,
  }> {
    try {
      return await fetchFromAPI<{
        result: Result,
        demographics: PreQuestionAnswer,
      }>(`/result/${id}`);
    } catch (error) {
      console.error('Error in fetchResult:', error);
      throw error;
    }
  }

  export async function fetchUserResults(userId: string): Promise<{
    userResults: UserResult[],
  }> {
    try {
      return await fetchFromAPI<{
        userResults: UserResult[],
      }>(`/user-results/${userId}`);
    } catch (error) {
      console.error('Error in fetchUserResults:', error);
      throw error;
    }
  }
  