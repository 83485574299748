import React from 'react';
import { SectionResultOverview } from '../types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

interface SectionOverviewComponentProps {
  sectionResultOverview: SectionResultOverview;
}

const getGradeColor = (grade: string) => {
  switch (grade) {
    case 'A': return '#4caf50';
    case 'B': return '#8bc34a';
    case 'C': return '#ffc107';
    case 'D': return '#ff9800';
    case 'E': return '#f44336';
    default: return '#9e9e9e';
  }
};

const SectionOverviewComponent: React.FC<SectionOverviewComponentProps> = ({ sectionResultOverview }) => (
  <Box sx={{ textAlign: 'center', mb: 1 }}>
    <Typography variant="body2" noWrap sx={{ mb: 1 }}>
      {sectionResultOverview.section.title}
    </Typography>
    <Chip
      label={sectionResultOverview.grade}
      size="small"
      sx={{
        backgroundColor: getGradeColor(sectionResultOverview.grade),
        color: 'white',
        minWidth: '40px',
      }}
    />
  </Box>
);

export default SectionOverviewComponent;