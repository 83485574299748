import React, { useState } from 'react';
import { FeedbackType, SectionResult as SectionResultType } from '../types';
import QuestionResult from './QuestionResult';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import LineBreakTypography from './LineBreakTypography';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { trackToggleExpand } from '../firebase';

interface SectionResultProps {
  sectionResult: SectionResultType;
  feedbackType: FeedbackType;
}

const getGradeColor = (grade: string) => {
  switch (grade) {
    case 'A': return '#4caf50';
    case 'B': return '#8bc34a';
    case 'C': return '#ffc107';
    case 'D': return '#ff9800';
    case 'E': return '#f44336';
    default: return '#9e9e9e';
  }
};

const SectionResult: React.FC<SectionResultProps> = ({ sectionResult, feedbackType }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    trackToggleExpand();
    setIsExpanded(!isExpanded);
  };

  const feedbackTitle = feedbackType === 'complete_feedback' ? '詳細フィードバック' : 'PICK UP!';
  const feedbackDescription = feedbackType === 'complete_feedback' ? 'このセクションの質問に対するフィードバックを以下に示します。' : 'このセクションの代表的な質問と、それに対するフィードバックを以下に示します。';

  return (
    <Paper elevation={2} sx={{ p: 3, mt: 3, backgroundColor: '#ffffff' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" color="primary">{sectionResult.section.title}</Typography>
        <Chip
          label={`評価: ${sectionResult.grade}`}
          sx={{
            backgroundColor: getGradeColor(sectionResult.grade),
            color: 'white',
          }}
        />
      </Box>
      <Button
        onClick={toggleExpand}
        endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        sx={{ mb: 2 }}
      >
        {isExpanded ? '閉じる' : '詳しい解説を見る'}
      </Button>
      <Collapse in={isExpanded}>
      <Box sx={{ mt: 2 }}>
          <Typography 
            variant="h6" 
            sx={{ 
              mb: 2, 
              color: 'secondary.main',
              fontWeight: 'bold',
              borderLeft: '4px solid',
              borderColor: 'secondary.main',
              pl: 2
            }}
          >
            {sectionResult.section.title}の重要性
          </Typography>
          <LineBreakTypography
            text={`${sectionResult.section.intent}`}
            variant="body2"
            sx={{ mb: 3 }}
          />
        </Box>
        <Divider sx={{ mb: 3 }} />
        <Box sx={{ mt: 2 }}>
          <Typography 
            variant="h6" 
            sx={{ 
              mb: 2, 
              color: 'secondary.main',
              fontWeight: 'bold',
              borderLeft: '4px solid',
              borderColor: 'secondary.main',
              pl: 2
            }}
          >
            {feedbackTitle}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, fontStyle: 'italic' }}>
            {feedbackDescription}
          </Typography>
          <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
            {
              feedbackType === 'complete_feedback' && sectionResult.questions.map((question, index) => (
                <QuestionResult key={question.question.id} questionResult={question} />
              ))
            }
            {feedbackType === 'partial_feedback' && (
                <QuestionResult questionResult={sectionResult.questions[0]} />
              )
            }
          </Box>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default SectionResult;