import React, { useRef } from 'react';
import { Section, Question, Answer } from '../types';
import QuestionItem from './QuestionItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

interface QuestionSectionProps {
  section: Section;
  questions: Question[];
  answers: Answer[];
  onAnswer: (questionId: number, selectionNumber: number, selectionText: string) => void;
  isFirstQuestion: boolean;
}

const QuestionSection: React.FC<QuestionSectionProps> = ({ section, questions, answers, onAnswer, isFirstQuestion }) => {
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const scrollToQuestion = (index: number) => {
    questionRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <Paper elevation={0} sx={{ mb: 0, p: 3, backgroundColor: '#ffffff' }}>
      <Typography 
        variant="h5" 
        sx={{ 
          mb: 2, 
          color: 'primary.main',
          fontWeight: 'bold',
          borderLeft: '4px solid',
          borderColor: 'primary.main',
          pl: 2
        }}
      >
        {section.title}
      </Typography>
      <Box sx={{ mt: 2 }}>
        {questions.map((question, index) => (
          <div key={question.id} ref={el => questionRefs.current[index] = el}>
            <QuestionItem
              question={question}
              answer={answers.find(a => a.questionId === question.id)}
              onAnswer={onAnswer}
            />
          </div>
        ))}
      </Box>
    </Paper>
  );
};

export default QuestionSection;