import { Answer, Grade, PreQuestionAnswer, Question, Result, Section, GradeThresholds } from "../types";

const sectionGradeThresholds: { [key: string]: GradeThresholds } = {
  lifestyle: {
    A: 7.56,
    B: 6.19,
    C: 4.81,
    D: 4.13,
    E: -100
  },
  digitalAttitude: {
    A: 6.31,
    B: 5.10,
    C: 3.90,
    D: 2.69,
    E: -100
  },
  relationship: {
    A: 9.53,
    B: 8.11,
    C: 6.69,
    D: 5.27,
    E: -100
  },
  selfAffirmation: {
    A: 9.50,
    B: 8.57,
    C: 7.63,
    D: 6.20,
    E: -100
  },
  mindset: {
    A: 4.38,
    B: 3.46,
    C: 2.54,
    D: 1.62,
    E: -100
  },
  // 他のセクションの閾値を追加
};

const totalGradeThresholds: GradeThresholds = {
  A: 36.8,
  B: 31.3,
  C: 25.9,
  D: 23.2,
  E: -100
};

const overallComments = {
  A: "素晴らしい結果です！家庭の健康状態が非常に良好です。",
  B: "良好な結果です。さらなる改善の余地はありますが、全体的に健康的な家庭環境が整っています。",
  C: "平均的な結果です。改善の余地がある部分に注目し、家庭環境の向上を目指しましょう。",
  D: "改善の余地が多くあります。具体的な対策を立てて、家庭環境の改善に取り組みましょう。",
  E: "早急な改善が必要です。専門家のアドバイスを受けながら、家庭環境の改善に取り組んでください。"
};

export function calculateResult(
  questions: Question[],
  sections: Section[],
  answers: Answer[],
  preQuestionAnswers: PreQuestionAnswer
): Result {
  const getScoreForGrade = (points: { elementaryLow: number; elementaryHigh: number; juniorHigh: number; highSchool: number }, grade: string): number => {
    if (grade.includes('小学1') || grade.includes('小学2') || grade.includes('小学3')) {
      return points.elementaryLow;
    } else if (grade.includes('小学4') || grade.includes('小学5') || grade.includes('小学6')) {
      return points.elementaryHigh;
    } else if (grade.includes('中学')) {
      return points.juniorHigh;
    } else if (grade.includes('高校')) {
      return points.highSchool;
    }
    return 0; // デフォルト値
  };

  const grade = preQuestionAnswers.grade;

  const sectionResults = sections.map(section => {
    const sectionQuestions = questions.filter(q => q.sectionId === section.id);
    const questionResults = sectionQuestions.map(question => {
      const answer = answers.find(a => a.questionId === question.id);
      if (!answer) {
        throw new Error(`回答が見つかりません: 質問ID ${question.id}`);
      }
      const selectedOption = question.selections.find(o => o.selectionNumber === answer.selectionNumber);
      if (!selectedOption) {
        throw new Error(`選択肢が見つかりません: 質問ID ${question.id}, 選択肢番号 ${answer.selectionNumber}`);
      }
      return {
        question: question,
        answer: answer,
        feedback: selectedOption.feedback,
        score: getScoreForGrade(selectedOption.points, grade)
      };
    });

    const totalPoints = questionResults.reduce((sum, qr) => sum + qr.score, 0);

    return {
      section: section,
      totalPoints: totalPoints,
      grade: calculateSectionGrade(totalPoints, section.id),
      questions: questionResults
    };
  });

  const overallTotalPoints = sectionResults.reduce((sum, sr) => sum + sr.totalPoints, 0);
  const overallGrade = calculateTotalGrade(overallTotalPoints);

  const result =  {
    totalPoints: overallTotalPoints,
    overallGrade: overallGrade,
    overallComment: getOverallComment(overallGrade),
    sectionResults: sectionResults
  };
  // console.log(JSON.stringify(result, null, 2));
  return result;
}

function getOverallComment(grade: Grade): string {
  return overallComments[grade];
}

function calculateSectionGrade(points: number, sectionId: string): Grade {
  // セクションの閾値を取得、存在しない場合はデフォルト値を使用
  const thresholds = sectionGradeThresholds[sectionId];

  if (points >= thresholds.A) {
    return 'A';
  } else if (points >= thresholds.B) {
    return 'B';
  } else if (points >= thresholds.C) {
    return 'C';
  } else if (points >= thresholds.D) {
    return 'D';
  } else {
    return 'E';
  }
}

function calculateTotalGrade(points: number): Grade {
  if (points >= totalGradeThresholds.A) {
    return 'A';
  } else if (points >= totalGradeThresholds.B) {
    return 'B';
  } else if (points >= totalGradeThresholds.C) {
    return 'C';
  } else if (points >= totalGradeThresholds.D) {
    return 'D';
  } else {
    return 'E';
  }
}
