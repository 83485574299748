import React from 'react';
import { ResultOverview } from '../types';
import SectionOverview from './SectionOverviewComponent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import SectionOverviewComponent from './SectionOverviewComponent';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Legend } from 'recharts';

interface ResultOverviewComponentProps {
  resultOverview: ResultOverview;
}

const getGradeColor = (grade: string) => {
  switch (grade) {
    case 'A': return '#4caf50';
    case 'B': return '#8bc34a';
    case 'C': return '#ffc107';
    case 'D': return '#ff9800';
    case 'E': return '#f44336';
    default: return '#9e9e9e';
  }
};

const gradeToScore = (grade: string): number => {
  switch (grade) {
    case 'A': return 5;
    case 'B': return 4;
    case 'C': return 3;
    case 'D': return 2;
    case 'E': return 1;
    default: return 0;
  }
};

const averageGrades = ['B', 'B', 'B', 'B', 'B'];

const ResultOverviewComponent: React.FC<ResultOverviewComponentProps> = ({ resultOverview }) => {
  const radarData = resultOverview.sectionResultOverviews.map((sectionResultOverview, index) => ({
    subject: sectionResultOverview.section.title,
    score: gradeToScore(sectionResultOverview.grade),
    average: gradeToScore(averageGrades[index]),
    fullMark: 5,
  }));

  const gradeLabels = ['E', 'D', 'C', 'B', 'A'];

  const customTickFormatter = (value: number): string => {
    const index = Math.round(value) - 1;
    return index >= 0 && index < gradeLabels.length ? gradeLabels[index] : '';
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 3, backgroundColor: '#ffffff' }}>
      <Typography variant="h4" gutterBottom align="center" color="primary">
        総合結果
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}>
        <Chip
          label={`評価: ${resultOverview.overallGrade}`}
          sx={{
            fontSize: '1.5rem',
            padding: '20px',
            backgroundColor: getGradeColor(resultOverview.overallGrade),
            color: 'white',
          }}
        />
      </Box>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        {resultOverview.overallComment}
      </Typography>
      <Box sx={{ height: 400, width: '100%', mb: 3 }}>
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarData}>
            <PolarGrid gridType="polygon" />
            <PolarAngleAxis 
              dataKey="subject"
              tick={(props) => {
                const { x, y, payload } = props;
                return (
                  <g transform={`translate(${x},${y})`}>
                    <text 
                      x={0} 
                      y={0} 
                      dy={16} 
                      textAnchor="middle" 
                      fill="#666" 
                      fontSize="12px"
                      transform={`rotate(${payload.angle - 90})`}
                    >
                      {payload.value}
                    </text>
                  </g>
                );
              }}
            />
            <PolarRadiusAxis 
              angle={90} 
              domain={[0, 5]}
              tickCount={6}
              tickFormatter={customTickFormatter}
            />
            <Radar 
              name="あなたの評価" 
              dataKey="score" 
              stroke="#FF5722" 
              fill="#FF5722" 
              fillOpacity={0.3} 
              strokeWidth={2}
            />
            <Radar 
              name="目指したい評価" 
              dataKey="average" 
              stroke="#2196F3" 
              fill="#2196F3" 
              fillOpacity={0.3} 
              strokeWidth={2}
              strokeDasharray="5 5"
            />
            <Legend 
              align="center"
              verticalAlign="bottom"
              iconType="circle"
            />
          </RadarChart>
        </ResponsiveContainer>
      </Box>
      <Typography variant="body2" sx={{ 
        fontWeight: 'bold', 
        color: '#1976d2', 
        mb: 1,
        display: 'inline-block',
        backgroundColor: '#e3f2fd',
        px: 1,
        py: 0.5,
        borderRadius: 1,
      }}>
        セクション別評価
      </Typography>
      <Grid container spacing={1}>
        {resultOverview.sectionResultOverviews.map(sectionResultOverview => (
          <Grid item xs={6} sm={4} md={2} key={sectionResultOverview.section.id}>
            <SectionOverviewComponent sectionResultOverview={sectionResultOverview} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default ResultOverviewComponent;