import React from 'react';
import { Link as MuiLink, keyframes, styled } from '@mui/material';
import { trackBunnerClick } from '../firebase';

const softShake = keyframes`
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(3px); }
  50% { transform: translateX(-3px); }
  75% { transform: translateX(3px); }
`;

const periodicSoftShake = keyframes`
  0%, 90%, 100% { transform: translateX(0); }
  92% { transform: translateX(3px); }
  94% { transform: translateX(-3px); }
  96% { transform: translateX(3px); }
  98% { transform: translateX(-3px); }
`;

const AnimatedImg = styled('img')<{ $animate: boolean }>(({ $animate }) => ({
  width: '100%',
  height: 'auto',
  display: 'block',
  objectFit: 'contain',
  animation: $animate ? `${periodicSoftShake} 3s ease-in-out infinite` : 'none',
  transition: 'transform 0.3s ease-in-out',
}));

interface BannerProps {
  imageUrl: string;
  altText: string;
  maxWidth?: string | number;
  maxHeight?: string | number;
  link?: string;
  onClick?: () => void;
  animate?: boolean;
  trackingName?: string;  // 新しいプロパティ
}

const Banner: React.FC<BannerProps> = ({ 
  imageUrl, 
  altText, 
  maxWidth = '100%', 
  maxHeight = 'auto',
  link,
  onClick,
  animate = false,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault(); // デフォルトの動作を防ぐ
    if (onClick) {
      onClick();
    }
    // リンクがある場合、デフォルトの動作を許可
    if (link) {
      window.open(link, '_blank', 'noopener,noreferrer');
    }
  };

  const content = (
    <AnimatedImg
      src={imageUrl}
      alt={altText}
      $animate={animate}
      style={{
        cursor: link || onClick ? 'pointer' : 'default',
      }}
    />
  );

  const wrapperStyle = {
    maxWidth, 
    maxHeight, 
    margin: '0 auto', 
    overflow: 'hidden'
  };

  if (link) {
    return (
      <MuiLink 
        href={link} 
        style={wrapperStyle}
        onClick={handleClick}
      >
        {content}
      </MuiLink>
    );
  }

  return (
    <div onClick={handleClick} style={wrapperStyle}>
      {content}
    </div>
  );
};

export default Banner;