import Papa from 'papaparse';
import { Question, Section, Selection, Answer, PreQuestionData, PreQuestionAnswer, Result } from './types';

function loadCSV(url: string): Promise<any[]> {
  return new Promise((resolve, reject) => {
    Papa.parse(url, {
      download: true,
      header: true,
      complete: (results) => {
        resolve(results.data);
      },
      error: (error) => {
        reject(error);
      }
    });
  });
}

export async function fetchQuestions(): Promise<Question[]> {
  const data = await loadCSV('/csv/questions.csv');
  return data.map((row: any) => ({
    ...row,
    id: parseInt(row.id),
    questionNumber: parseInt(row.questionNumber),
    selections: [] // このオプションは後で設定します
  }));
}

export async function fetchSections(): Promise<Section[]> {
  const data = await loadCSV('/csv/sections.csv');
  return data.map((row: any) => ({
    ...row,
    id: row.id
  }));
}

export async function fetchSelections(): Promise<Selection[]> {
  const data = await loadCSV('/csv/selections.csv');
  return data.map((row: any) => ({
    questionId: parseInt(row.questionId),
    selectionNumber: parseInt(row.selectionNumber),
    text: row.text,
    feedback: row.feedback,
    points: {
      elementaryLow: parseFloat(row.pointElementaryLow),
      elementaryHigh: parseFloat(row.pointElementaryHigh),
      juniorHigh: parseFloat(row.pointJuniorHigh),
      highSchool: parseFloat(row.pointHighSchool)
    }
  }));
}

export async function fetchPreQuestions(): Promise<PreQuestionData[]> {
    const preQuestions: PreQuestionData[] = [
        {
          name: 'grade',
          label: 'お子さんの学年',
          selections: [
            '小学1年生', '小学2年生', '小学3年生', '小学4年生', '小学5年生', '小学6年生',
            '中学1年生', '中学2年生', '中学3年生',
            '高校1年生', '高校2年生', '高校3年生'
          ]
        },
        {
          name: 'relation',
          label: '続柄',
          selections: ['父', '母', 'その他']
        },
        {
          name: 'prefecture',
          label: '都道府県',
          selections: [
            '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
            '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
            '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県',
            '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県',
            '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県',
            '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県',
            '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
          ]
        },
        {
          name: 'situation',
          label: 'お子さんの状況',
          selections: ['通学中', '不登校', 'その他']
        }
      ];
    
    // この関数は実際にはAPIからデータを取得する代わりに、
    // ローカルに定義された preQuestions を返します。
    // 将来的にAPIから取得する場合は、ここでフェッチ処理を実装します。
    return new Promise((resolve) => {
      setTimeout(() => resolve(preQuestions), 100); // 非同期処理をシミュレート
    });
  }

export async function fetchAllData(): Promise<{
    questions: Question[], 
    sections: Section[]
    preQuestions: PreQuestionData[]
}> {
  const [questions, sections, selections, preQuestions] = await Promise.all([
    fetchQuestions(),
    fetchSections(),
    fetchSelections(),
    fetchPreQuestions()
  ]);

  // 質問に選択肢を関連付ける
  questions.forEach(question => {
    question.selections = selections.filter(selection => selection.questionId === question.id);
  });

  return { questions, sections, preQuestions };
}

export async function submitFormData(preQuestionAnswers: PreQuestionAnswer, answers: Answer[]): Promise<string> {
  // ここでバックエンドAPIを呼び出してデータを保存し、IDを取得します
  // この例では、モックの実装を示します
  return new Promise((resolve) => {
    setTimeout(() => {
      const id = Math.random().toString(36).substr(2, 9);
      resolve(id);
    }, 1000);
  });
}

export async function fetchResult(id: string): Promise<Result> {
  // ここでバックエンドAPIを呼び出して結果を取得します
  // この例では、モックの実装を示します
  return new Promise((resolve, reject) => {
    fetch('/json/result.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setTimeout(() => {
          resolve(data as Result);
        }, 1000); // 1秒の遅延をシミュレート
      })
      .catch(error => {
        console.error('Error fetching mock data:', error);
        reject(error);
      });
  });
}