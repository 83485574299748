import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import FormPage from './pages/FormPage';
import ResultPage from './pages/ResultPage';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import UserResultsPage from './pages/UserResultPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#39b7ff',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, "Helvetica Neue", Arial, sans-serif',
  },
});

const App: React.FC = () => {
  useEffect(() => {
    logEvent(analytics, 'app_open');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<FormPage />} />
          <Route path="/result" element={<ResultPage />} />
          <Route path="/result/:id" element={<ResultPage />} /> {/* Add this line */}
          <Route path="/user-results/:userId" element={<UserResultsPage />} /> {/* Add this line */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;