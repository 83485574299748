import React from 'react';
import { QuestionResult as QuestionResultType } from '../types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Paper } from '@mui/material';
import LineBreakTypography from './LineBreakTypography';

interface QuestionResultProps {
  questionResult: QuestionResultType;
}

const QuestionResult: React.FC<QuestionResultProps> = ({ questionResult }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="h6" color="secondary" gutterBottom>
      {questionResult.question.title}
    </Typography>
    <LineBreakTypography
      text={`${questionResult.question.text}`}
      variant="body1"
    />
    <Box sx={{ 
      borderTop: '2px solid #e0e0e0', 
      pt: 2,
      mt: 2,
    }}>
      <Typography variant="body2" sx={{ 
        fontWeight: 'bold', 
        color: '#1976d2', 
        mb: 1,
        display: 'inline-block',
        backgroundColor: '#e3f2fd',
        px: 1,
        py: 0.5,
        borderRadius: 1,
      }}>
        質問の意図
      </Typography>
      <LineBreakTypography
        text={`${questionResult.question.intent}`}
        variant="body2"
        sx={{ mb: 1 }}
      />
    </Box>
    <Box sx={{ 
      borderTop: '2px solid #e0e0e0', 
      pt: 2,
      mt: 2,
    }}>
      <Typography variant="body2" sx={{ 
        fontWeight: 'bold', 
        color: '#1976d2', 
        mb: 1,
        display: 'inline-block',
        backgroundColor: '#e3f2fd',
        px: 1,
        py: 0.5,
        borderRadius: 1,
      }}>
        あなたの回答
      </Typography>
      <Chip label={questionResult.answer.selectionText} size="small" />
    </Box>
    <Box sx={{ 
      borderTop: '2px solid #e0e0e0', 
      pt: 2,
      mt: 2,
    }}>
      <Typography variant="body2" sx={{ 
        fontWeight: 'bold', 
        color: '#1976d2', 
        mb: 1,
        display: 'inline-block',
        backgroundColor: '#e3f2fd',
        px: 1,
        py: 0.5,
        borderRadius: 1,
      }}>
        フィードバック
      </Typography>
      <LineBreakTypography
        text={`${questionResult.feedback}`}
        variant="body2"
        sx={{ 
          mt: 1,
          p: 1.5, 
          backgroundColor: '#f5f5f5',
          borderRadius: 1,
        }}
      />
    </Box>
  </Box>
);

export default QuestionResult;